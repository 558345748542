import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    @font-face {
  font-family: "Pretendard-Regular";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-SemiBold";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")
    format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Bold";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff")
    format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard-ExtraBold";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff")
    format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard-Black";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff")
    format("woff");
  font-weight: 900;
  font-style: normal;
}


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
font-family: 'Pretendard-Regular';
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
display: block;
}
body {
line-height: 1;
}
ol, ul {
list-style: none;
}
blockquote, q {
quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
content: '';
content: none;
}
table {
border-collapse: collapse;
border-spacing: 0;
}
  `;

export const theme = {
    fontSize: {
        base: "8px",
        extra2: "5rem",
        extra: "4rem",
        logo: "3rem",
        large: "2rem",
        medium: "1.5rem",
        small: "1rem",
    },

    colors: {
        mainGradient: "linear-gradient(to right, #6bdffa, #9246fe)",
        sloganGradient:
            "-webkit-linear-gradient(left, #4E30F2, #5483F6, #6BD2FA)",
        purposeGradient: "linear-gradient(to right, #030F36, #071D64)",
    },

    fonts: {
        regular: "Pretendard-Regular, sans-serif",
        medium: "Pretendard-Medium, sans-serif",
        semibold: "Pretendard-Semibold, sans-serif",
        bold: "Pretendard-Bold, sans-serif",
        extraBold: "Pretendard-ExtraBold, sans-serif",
        black: "Pretendard-Black, sans-serif",
    },
};
