import React, { useState, useEffect, useRef } from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "./styles/theme";
import { Container } from "./components/Container";
import { PCHeader, MOHeader } from "./components/Header";
import Mainpage from "./components/Mainpage";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
} from "react-router-dom";
import { Contact } from "./components/Contact";
import { PcIntro } from "./components/PCIntro";
import MoIntro from "./components/MOIntro";
import Detail from "./components/Detail";
import Footer from "./components/Footer";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

    const [headerHeight, setHeaderHeight] = useState(0);
    const headerRef = useRef(null);

    useEffect(() => {
        const TabletResize = () => {
            setIsTablet(window.innerWidth <= 1024);
        };

        const MobileResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", MobileResize);
        window.addEventListener("resize", TabletResize);

        return () => {
            window.removeEventListener("resize", TabletResize);
            window.removeEventListener("resize", MobileResize);
        };
    }, []);

    useEffect(() => {
        const updateHeaderHeight = () => {
            if (headerRef.current) {
                const height = headerRef.current.offsetHeight;
                setHeaderHeight(height);
                console.log("Header height:", height);
            }
        };

        // 컴포넌트가 처음 마운트될 때 헤더 높이 계산
        updateHeaderHeight();

        // 윈도우 크기 변경 시 헤더 높이 다시 계산
        window.addEventListener("resize", updateHeaderHeight);

        return () => {
            window.removeEventListener("resize", updateHeaderHeight);
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <ScrollToTop />
                <GlobalStyle />
                {isTablet ? (
                    <MOHeader ref={headerRef} />
                ) : (
                    <PCHeader ref={headerRef} />
                )}

                <Container marginTop={`${headerHeight}px`}>
                    <Routes>
                        <Route path="/" element={<Mainpage />} />
                        <Route
                            path="/prdList"
                            element={isMobile ? <MoIntro /> : <PcIntro />}
                        />
                        <Route path="/detail/:id" element={<Detail />} />
                        <Route path="/ContactUs" element={<Contact />} />
                    </Routes>
                    <Footer />
                </Container>
            </Router>
        </ThemeProvider>
    );
}

export default App;
