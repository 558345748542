import styled from "styled-components";
import {
    CaseBtn,
    GradientText,
    ListBanner,
    ListContainer,
    ListContents,
    ListSub,
} from "./PCIntro";

const MoIntro = () => {
    return (
        <>
            <ListContainer>
                <ListContents>
                    <ListBanner>
                        Datacore의
                        <br />
                        <GradientText>차별화된 서비스를</GradientText>
                        <br />
                        만나보세요.
                    </ListBanner>
                    <ListSub>
                        실시간으로 변화하는 비즈니스 환경에
                        <br />
                        발빠르게 대응할 수 있는 서비스를 제공합니다.
                    </ListSub>
                </ListContents>
                <MoCaseContaienr>
                    <MoCases>
                        <MocaseTitle>Data Transformation</MocaseTitle>
                        <MocaseImg src="https://heidimoon.cafe24.com/renwal/powerBI_3_13.png" />
                        <MocaseText>
                            데이터가 곧 자산인 시대. 이를 위한 변화
                        </MocaseText>
                        <CaseBtn to="/detail/1">자세히보기</CaseBtn>
                    </MoCases>
                    <MoCases>
                        <MocaseTitle>Data Aggregation + Analytics</MocaseTitle>
                        <MocaseImg src="https://heidimoon.cafe24.com/renwal/powerBI_2.png" />
                        <MocaseText>
                            트랜드 변화를 파악을 남들보다 빠르게. Business
                            question의 답은 데이터에 있습니다.
                        </MocaseText>
                        <CaseBtn to="/detail/2">자세히보기</CaseBtn>
                    </MoCases>
                    <MoCases>
                        <MocaseTitle>
                            Machine Learning model building
                        </MocaseTitle>
                        <MocaseImg src="https://heidimoon.cafe24.com/renwal/powerBI_1.png" />
                        <MocaseText>
                            어렵게만 느껴졌던 머신러닝. 내 비지니스를 위한
                            커스터마이즈 된 솔루션.
                        </MocaseText>
                        <CaseBtn to="/detail/3">자세히보기</CaseBtn>
                    </MoCases>
                </MoCaseContaienr>
            </ListContainer>
        </>
    );
};

const MoCaseContaienr = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 16px;
    min-width: 320px;
    box-sizing: border-box;
    padding-bottom: 10rem;
`;

const MoCases = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f0f0f0;
    margin-top: 8rem;
    border-radius: 24px;
    padding: 16px;
`;

const MocaseTitle = styled.p`
    margin-top: 2rem;
    font-size: ${({ theme }) => theme.fontSize.large};
    font-family: ${({ theme }) => theme.fonts.bold} !important;

    color: #000000;
    margin-bottom: 1rem;
`;

const MocaseImg = styled.img`
    width: 100%;
    box-sizing: border-box;
    object-fit: contain;
`;

const MocaseText = styled.p`
    font-size: ${({ theme }) => theme.fontSize.small};
    font-family: ${({ theme }) => theme.fonts.regular} !important;
    color: #000000;
    margin: 1rem 0;
`;

export default MoIntro;
