import React, { useRef, useState } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";

export const Contact = () => {
    const form = useRef();
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [otherMessage, setOtherMessage] = useState("");
    const [consent1, setConsent1] = useState(false);
    // const [consent2, setConsent2] = useState(false);

    const handleCheckboxChange = (e) => {
        if (e.target.value === "Other") {
            setIsOtherSelected(e.target.checked);
        }
    };

    const handleConsentChange = (e) => {
        const { name, checked } = e.target;
        if (name === "consent1") {
            setConsent1(checked);
        }
        //  else if (name === "consent2") {
        //     setConsent2(checked);
        // }
    };

    const sendEmail = (e) => {
        e.preventDefault();

        if (!consent1) {
            alert("모든 필수 동의 사항에 동의해주세요.");
            return;
        }

        const checkboxes = form.current.querySelectorAll(
            'input[name="message"]:checked',
        );

        // 일반적인 체크박스의 메시지들을 배열로 담음 (Other 제외)
        let combinedMessages = Array.from(checkboxes)
            .filter((checkbox) => checkbox.value !== "Other") // "Other"를 제외
            .map((checkbox) => checkbox.value)
            .join(", ");

        // "기타" 메시지가 입력되어 있으면 추가
        if (isOtherSelected && otherMessage.trim()) {
            combinedMessages += `기타: ${otherMessage.trim()}`;
        }

        let messageInput = form.current.querySelector(
            'input[name="combined_message"]',
        );
        if (!messageInput) {
            messageInput = document.createElement("input");
            messageInput.type = "hidden";
            messageInput.name = "combined_message";
            form.current.appendChild(messageInput);
        }
        messageInput.value = combinedMessages.trim();

        emailjs
            .sendForm(
                "service_i164r1m",
                "template_q1lk9jd",
                form.current,
                "iKv2VPT8becDADY_X",
            )
            .then(
                () => {
                    console.log("SUCCESS!");
                    alert(
                        "문의가 성공적으로 접수되었습니다. 빠른 시일 내에 연락드리겠습니다.",
                    );
                },
                (error) => {
                    console.log("FAILED...", error.text);
                },
            );
    };

    return (
        <ContactContainer>
            <ContactImage>
                <ContactBg>
                    <ContactWrapper>
                        <FormWrapper ref={form} onSubmit={sendEmail}>
                            <Label>희망 서비스</Label>
                            <Input type="text" name="service" />
                            <Label>성함</Label>
                            <Input type="text" name="name" />
                            <Label>이메일</Label>
                            <Input type="email" name="email" />
                            <Label>연락처</Label>
                            <Input type="text" name="phone" />
                            <Label>Message</Label>
                            <CheckboxWrapper>
                                <CheckboxLabel>
                                    <input
                                        type="checkbox"
                                        name="message"
                                        value="DB제작 및 파이프 라인"
                                    />
                                    DB제작 및 파이프 라인
                                </CheckboxLabel>
                                <CheckboxLabel>
                                    <input
                                        type="checkbox"
                                        name="message"
                                        value="데이터 분석"
                                    />
                                    데이터 분석
                                </CheckboxLabel>
                                <CheckboxLabel>
                                    <input
                                        type="checkbox"
                                        name="message"
                                        value="디지털 트랜스포메이션 컨설팅"
                                    />
                                    디지털 트랜스포메이션 컨설팅
                                </CheckboxLabel>
                                <CheckboxLabel>
                                    <input
                                        type="checkbox"
                                        name="message"
                                        value="Other"
                                        onChange={handleCheckboxChange}
                                    />
                                    기타
                                    <OtherInput
                                        type="text"
                                        placeholder="기타 내용 입력"
                                        onChange={(e) =>
                                            setOtherMessage(e.target.value)
                                        }
                                        disabled={!isOtherSelected}
                                    />
                                </CheckboxLabel>
                            </CheckboxWrapper>
                            <ConsentWrapper>
                                <ConsentSection>
                                    <ConsentText>
                                        <strong>
                                            개인정보 수집 및 이용 동의
                                        </strong>
                                    </ConsentText>
                                    <ConsentText>
                                        개인정보처리방침닫기 회사명(이하
                                        ‘회사’라 한다)는 개인정보 보호법
                                        제30조에 따라 정보 주체의 개인정보를
                                        보호하고 이와 관련한 고충을 신속하고
                                        원활하게 처리할 수 있도록 하기 위하여
                                        다음과 같이 개인정보 처리지침을 수립,
                                        공개합니다. 제1조 (개인정보의 처리목적)
                                        회사는 다음의 목적을 위하여 개인정보를
                                        처리합니다. 처리하고 있는 개인정보는
                                        다음의 목적 이외의 용도로는 이용되지
                                        않으며, 이용 목적이 변경되는 경우에는
                                        개인정보보호법 제18조에 따라 별도의
                                        동의를 받는 등 필요한 조치를 이행할
                                        예정입니다. 1. 홈페이지 회원 가입 및
                                        관리 회원 가입 의사 확인, 회원제 서비스
                                        제공에 따른 본인 식별․인증, 회원자격
                                        유지․관리, 제한적 본인확인제 시행에 따른
                                        본인확인, 서비스 부정 이용 방지, 만 14세
                                        미만 아동의 개인정보처리 시 법정대리인의
                                        동의 여부 확인, 각종 고지․통지, 고충
                                        처리 등을 목적으로 개인정보를
                                        처리합니다. 2. 재화 또는 서비스 제공
                                        물품 배송, 서비스 제공, 계약서 및 청구서
                                        발송, 콘텐츠 제공, 맞춤서비스 제공,
                                        본인인증, 연령인증, 요금 결제 및 정산,
                                        채권추심 등을 목적으로 개인정보를
                                        처리합니다. 3. 고충 처리 민원인의 신원
                                        확인, 민원사항 확인, 사실조사를 위한
                                        연락․통지, 처리 결과 통보 등의 목적으로
                                        개인정보를 처리합니다. 제2조 (개인정보의
                                        처리 및 보유기간) ① 회사는 법령에 따른
                                        개인정보 보유, 이용 기간 또는
                                        정보주체로부터 개인정보를 수집 시에 동의
                                        받은 개인정보 보유, 이용 기간 내에서
                                        개인정보를 처리, 보유합니다. ② 각각의
                                        개인정보 처리 및 보유 기간은 다음과
                                        같습니다. 1. 홈페이지 회원 가입 및 관리
                                        : 사업자/단체 홈페이지 탈퇴 시까지 다만,
                                        다음의 사유에 해당하는 경우에는 해당
                                        사유 종료 시까지 1-1 관계 법령 위반에
                                        따른 수사, 조사 등이 진행 중인 경우에는
                                        해당 수사, 조사 종료 시까지 1-2 홈페이지
                                        이용에 따른 채권 및 채무관계 잔존 시에는
                                        해당 채권, 채무 관계 정산 시까지 2. 재화
                                        또는 서비스 제공 : 재화․서비스 공급완료
                                        및 요금결제․정산 완료 시까지 다만,
                                        다음의 사유에 해당하는 경우에는 해당
                                        기간 종료 시까지 2-1 「전자상거래
                                        등에서의 소비자 보호에 관한 법률」에
                                        따른 표시․광고, 계약내용 및 이행 등
                                        거래에 관한 기록 - 표시․광고에 관한 기록
                                        : 6월 - 계약 또는 청약 철회, 대금결제,
                                        재화 등의 공급기록 : 5년 - 소비자 불만
                                        또는 분쟁 처리에 관한 기록 : 3년 2-2
                                        「통신비밀보호법」 제41조에 따른
                                        통신사실확인자료 보관 - 가입자
                                        전기통신일시, 개시․종료 시간, 상대방
                                        가입자 번호, 사용도수, 발신기지국
                                        위치추적자료 : 1년 - 컴퓨터 통신, 인터넷
                                        로그 기록자료, 접속지 추적자료 : 3개월
                                        제3조 (개인정보의 제3자 제공) ① 회사는
                                        정보주체의 개인정보를 제1조(개인정보의
                                        처리목적)에서 명시한 범위 내에서만
                                        처리하며, 정보주체의 동의, 법률의 특별한
                                        규정 등 개인정보 보호법 제17조에
                                        해당하는 경우에만 개인정보를 제3자에게
                                        제공합니다. ② 회사는 다음과 같이
                                        개인정보를 제3자에게 제공하고 있습니다.
                                        - 개인정보를 제공받는 자 : 정재혁 -
                                        제공받는 자의 개인정보 이용목적 : 데이터
                                        상품 제공 - 제공하는 개인정보 항목 :
                                        성명, 주소, 전화번호, 이메일주소 -
                                        제공받는 자의 보유, 이용기간 : 1년
                                        제5조(이용자 및 법정대리인의 권리와 그
                                        행사 방법) ① 정보주체는 회사에 대해
                                        언제든지 다음 각 호의 개인정보 보호 관련
                                        권리를 행사할 수 있습니다. 1. 개인정보
                                        열람 요구 2. 오류 등이 있을 경우 정정
                                        요구 3. 삭제요구 4. 처리정지 요구 ②
                                        제1항에 따른 권리 행사는 회사에 대해
                                        서면, 전화, 전자우편, 모사전송(FAX) 등을
                                        통하여 하실 수 있으며 회사는 이에 대해
                                        지체없이 조치하겠습니다. ③ 정보주체가
                                        개인정보의 오류 등에 대한 정정 또는
                                        삭제를 요구한 경우에는 회사는 정정 또는
                                        삭제를 완료할 때까지 당해 개인정보를
                                        이용하거나 제공하지 않습니다. ④ 제1항에
                                        따른 권리 행사는 정보주체의
                                        법정대리인이나 위임을 받은 자 등
                                        대리인을 통하여 하실 수 있습니다. 이
                                        경우 개인정보 보호법 시행규칙 별지
                                        제11호 서식에 따른 위임장을 제출하셔야
                                        합니다. ⑤ 정보주체는 개인정보 보호법 등
                                        관계 법령을 위반하여 회사가 처리하고
                                        있는 정보주체 본인이나 타인의 개인정보
                                        및 사생활을 침해하여서는 아니 됩니다.
                                        제7조(개인정보의 파기) ① 회사는 개인정보
                                        보유 기간의 경과, 처리목적 달성 등
                                        개인정보가 불필요하게 되었을 때에는
                                        지체없이 해당 개인정보를 파기합니다. ②
                                        정보주체로부터 동의받은 개인정보 보유
                                        기간이 경과하거나 처리목적이
                                        달성되었음에도 불구하고 다른 법령에 따라
                                        개인정보를 계속 보존하여야 하는
                                        경우에는, 해당 개인정보를 별도의
                                        데이터베이스(DB)로 옮기거나 보관장소를
                                        달리하여 보존합니다. ③ 개인정보 파기의
                                        절차 및 방법은 다음과 같습니다. 1. 파기
                                        절차 회사는 파기 사유가 발생한
                                        개인정보를 선정하고, 회사의 개인정보
                                        보호책임자의 승인을 받아 개인정보를
                                        파기합니다. 2. 파기 방법 회사는 전자적
                                        파일 형태로 기록․저장된 개인정보는
                                        기록을 재생할 수 없도록 로우레밸포멧(Low
                                        Level Format) 등의 방법을 이용하여
                                        파기하며, 종이 문서에 기록․저장된
                                        개인정보는 분쇄기로 분쇄하거나 소각하여
                                        파기합니다. 제8조(개인정보의 안전성
                                        확보조치) 회사는 개인정보의 안전성
                                        확보를 위해 다음과 같은 조치를 하고
                                        있습니다. 1. 관리적 조치 : 내부관리계획
                                        수립 및 시행, 정기적 직원 교육 등 2.
                                        기술적 조치 : 개인정보처리시스템 등의
                                        접근 권한 관리, 접근통제시스템 설치,
                                        고유 식별정보 등의 암호화, 보안프로그램
                                        설치 3. 물리적 조치 : 전산실, 자료보관실
                                        등의 접근통제 제9조(개인정보 자동 수집
                                        장치의 설치∙운영 및 거부에 관한 사항) ①
                                        회사는 이용자에게 개별적인 맞춤 서비스를
                                        제공하기 위해 이용정보를 저장하고 수시로
                                        불러오는 ‘쿠키(cookie)’를 사용합니다. ②
                                        쿠키는 웹사이트를 운영하는데 이용되는
                                        서버(http)가 이용자의 컴퓨터 브라우저에
                                        보내는 소량의 정보이며 이용자들의 컴퓨터
                                        내의 하드디스크에 저장되기도 합니다. 가.
                                        쿠키의 사용 목적: 이용자가 방문한 각
                                        서비스와 웹 사이트들에 대한 방문 및
                                        이용형태, 인기 검색어, 보안접속 여부,
                                        등을 파악하여 이용자에게 최적화된 정보
                                        제공을 위해 사용됩니다. 나. 쿠키의
                                        설치∙운영 및 거부 : 웹브라우저 상단의
                                        도구-인터넷 옵션-개인정보 메뉴의 옵션
                                        설정을 통해 쿠키 저장을 거부 할 수
                                        있습니다. 다. 쿠키 저장을 거부할 경우
                                        맞춤형 서비스 이용에 어려움이 발생할 수
                                        있습니다. 제10조(개인정보 보호책임자) ①
                                        회사는 개인정보 처리에 관한 업무를
                                        총괄해서 책임지고, 개인정보 처리와
                                        관련한 정보주체의 불만 처리 및 피해구제
                                        등을 위하여 아래와 같이 개인정보
                                        보호책임자를 지정하고 있습니다. ▶
                                        개인정보 보호책임자 성명 : 정재혁 직책 :
                                        CEO 연락처 : 010-8549-2249 ※ 개인정보
                                        보호 담당부서로 연결됩니다. ②
                                        정보주체께서는 회사의 서비스(또는
                                        사업)을 이용하시면서 발생한 모든
                                        개인정보 보호 관련 문의, 불만 처리,
                                        피해구제 등에 관한 사항을 개인정보
                                        보호책임자 및 담당부서로 문의하실 수
                                        있습니다. 회사는 정보주체의 문의에 대해
                                        지체없이 답변 및 처리해드릴 것입니다.
                                        제11조(개인정보 열람청구) 정보주체는
                                        개인정보 보호법 제35조에 따른 개인정보의
                                        열람 청구를 아래의 부서에 할 수
                                        있습니다. 회사는 정보주체의 개인정보
                                        열람 청구가 신속하게 처리되도록
                                        노력하겠습니다. ▶ 개인정보 열람청구
                                        접수․처리 부서 담당자 : 정재혁 연락처 :
                                        010-8549-2249 제12조(권익침해 구제 방법)
                                        정보주체는 아래의 기관에 대해 개인정보
                                        침해에 대한 피해구제, 상담 등을 문의하실
                                        수 있습니다. ▶ 개인정보 침해신고센터
                                        (한국인터넷진흥원 운영) - 소관 업무 :
                                        개인정보 침해사실 신고, 상담 신청 -
                                        홈페이지 : privacy.kisa.or.kr - 전화 :
                                        (국번없이) 118 - 주소 : (58324) 전남
                                        나주시 진흥길 9(빛가람동 301-2) 3층
                                        개인정보침해신고센터 ▶ 개인정보
                                        분쟁조정위원회 - 소관업무 : 개인정보
                                        분쟁조정신청, 집단분쟁조정 (민사적 해결)
                                        - 홈페이지 : www.kopico.go.kr - 전화 :
                                        (국번없이) 1833-6972 - 주소 :
                                        (03171)서울특별시 종로구 세종대로 209
                                        정부서울청사 4층 ▶ 대검찰청
                                        사이버범죄수사단 : 02-3480-3573
                                        (www.spo.go.kr) ▶ 경찰청 사이버안전국 :
                                        182 (http://cyberbureau.police.go.kr)
                                        제13조(개인정보 처리방침 시행 및 변경)
                                        이 개인정보 처리방침은 2024. 09. 29부터
                                        적용됩니다.
                                    </ConsentText>
                                </ConsentSection>
                                <ConsentCheckboxLabel>
                                    <input
                                        type="checkbox"
                                        name="consent1"
                                        checked={consent1}
                                        onChange={handleConsentChange}
                                    />
                                    개인정보 수집 및 이용에 동의합니다.
                                </ConsentCheckboxLabel>

                                {/* <ConsentSection>
                                    <ConsentText>
                                        <strong></strong>
                                        <br />
                                    </ConsentText>
                                </ConsentSection>
                                <ConsentCheckboxLabel>
                                    <input
                                        type="checkbox"
                                        name="consent2"
                                        checked={consent2}
                                        onChange={handleConsentChange}
                                    />
                                    개인정보 제3자 제공에 대해 동의합니다.
                                </ConsentCheckboxLabel> */}
                            </ConsentWrapper>
                            <SubmitButton type="submit" value="Send" />
                        </FormWrapper>
                    </ContactWrapper>
                </ContactBg>
            </ContactImage>
        </ContactContainer>
    );
};

// Styled Components
const ContactContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media all and (max-width: 390px) {
        height: 150vh;
    }
`;

const ContactBg = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
`;

const ContactImage = styled.div`
    background-image: url(https://cdn.imweb.me/thumbnail/20240503/37ae2199afade.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
`;

const ContactWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
`;

const FormWrapper = styled.form`
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 50px;
    width: 90%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    @media all and (max-width: 576px) {
        padding: 20px;
    }
`;

const Label = styled.label`
    display: block;
    font-weight: bold;
    width: 80%;
    margin: 0 auto;
    font-size: 0.8rem;
    font-family: ${(props) => props.theme.fonts.semibold};

    @media all and (max-width: 576px) {
        width: 100%;
    }
`;

const Input = styled.input`
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 80%;
    padding: 1.5% 0;
    margin: 0 auto;

    @media all and (max-width: 576px) {
        width: 100%;
    }
`;

const SubmitButton = styled.input`
    padding: 10px 15px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #45a049;
    }
`;

const CheckboxWrapper = styled.div`
    width: 80%;
    margin: 0 auto;

    @media all and (max-width: 576px) {
        width: 100%;
    }
`;

const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    font-family: ${(props) => props.theme.fonts.medium};
    font-size: 0.8rem;
`;

const OtherInput = styled.input`
    margin-left: 8px;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    disabled: ${(props) => (props.disabled ? "true" : "false")};
`;

const ConsentWrapper = styled.div`
    width: 80%;
    margin: 16px auto;
    padding: 10px;
    border-radius: 4px;

    @media all and (max-width: 576px) {
        width: 100%;
    }
`;

const ConsentSection = styled.div`
    margin-bottom: 16px;
    height: 180px;
    overflow-y: scroll;
    border: 1px solid #f4f4f4;
    padding: 8px;
`;

const ConsentText = styled.p`
    font-size: 0.8rem;
    color: #333;
    line-height: 1.5;
    margin-bottom: 8px;
`;

const ConsentCheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    color: #333;
    margin-bottom: 8px;
`;
