import styled from "styled-components";
import { AlignmentContainer } from "./Container";
import { Link } from "react-router-dom";

const Mainpage = () => {
    const preventVideoClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <>
            <MainContainer>
                <MainBg />
                <VideoBg autoPlay loop muted onClick={preventVideoClick}>
                    <source
                        src="https://wholesumbrands.cafe24.com/poveo/promotion/main2.mp4"
                        type="video/mp4"
                    />
                </VideoBg>
                <MainContents>
                    <MainText>
                        Best Performance
                        <br /> Through Analytics
                    </MainText>
                    <MainSub>
                        데이터 기반 솔루션 개발을 전문으로,
                        <Mobr /> 빅데이터 분석을 통한
                        <Pcbr /> 실용적이고 혁신적인
                        <Mobr /> IT 서비스를 제공합니다.
                    </MainSub>
                </MainContents>
            </MainContainer>

            <SloganContainer>
                <SloganBgImage></SloganBgImage>
                <SloganBg></SloganBg>
                <SloganTitle>
                    EVER
                    <MnBr1025 /> BETTER
                </SloganTitle>
                <SloganSub>
                    자동화 분석을 통해서 전보다
                    <MnBr1025 /> 더 나은 비즈니스 모델로의 전환.
                </SloganSub>
            </SloganContainer>

            <PurposeContainer>
                <PurposeBg src="https://heidimoon.cafe24.com/promotion/0820/purpose_Bg.png" />
                <AlignmentContainer>
                    <PurposeTitle>
                        Datacore가
                        <br />
                        필요한 이유.
                    </PurposeTitle>
                    <PurposeSub>
                        수많은 데이터 속에서 가치 있는 인사이트를 도출하고,
                        <MXBR375 /> 이를 바탕으로 기업의 의사결정 프로세스를
                        지원합니다.
                    </PurposeSub>
                    <GridContainer>
                        <GridItem>
                            <Image
                                src="https://heidimoon.cafe24.com/promotion/0820/dashboard.svg"
                                alt="Placeholder Image"
                            />
                            <Title>
                                Data기반의 Decision
                                <br /> Making을 하고 싶은데
                                <br />
                                어디서 시작할지 모를 때
                            </Title>
                        </GridItem>
                        <GridItem>
                            <Image
                                src="https://heidimoon.cafe24.com/promotion/0820/technology.svg"
                                alt="Placeholder Image"
                            />
                            <Title>
                                회사 내/외부의 Data를
                                <br /> 모으고 파악하고 싶을 때
                            </Title>
                        </GridItem>
                        <GridItem>
                            <Image
                                src="https://heidimoon.cafe24.com/promotion/0820/artificial-intelligence.svg"
                                alt="Placeholder Image"
                            />
                            <Title>
                                인공지능 모델들은 발전
                                <MXBR375 />
                                하는데 어떻게 적용해야
                                <MXBR375 />
                                할지 모르겠을 때
                            </Title>
                        </GridItem>
                        <GridItem>
                            <Image
                                src="https://heidimoon.cafe24.com/promotion/0820/growth.svg"
                                alt="Placeholder Image"
                            />
                            <Title>
                                비즈니스 성장이
                                <br />
                                정체되어 있을 때
                            </Title>
                        </GridItem>
                    </GridContainer>
                    <BtnContainer>
                        <MainDetailBtn>
                            <PurposeLink to="/prdList">자세히보기</PurposeLink>
                        </MainDetailBtn>
                        <MainInquiryBtn>
                            <PurposeLink to="/ContactUs">문의하기</PurposeLink>
                        </MainInquiryBtn>
                    </BtnContainer>
                </AlignmentContainer>
            </PurposeContainer>
            <CareerContainer>
                <CareerSub>CAREER</CareerSub>
                <CareerTitle>Datacore History</CareerTitle>
                <CareerContent>
                    국내/외 주요 기업들의 디지털 전환을 위한
                    <br />
                    디지털 혁신 파트너로 자리매김하였습니다.
                </CareerContent>
            </CareerContainer>
            <BrandContainer>
                <BrandTitle>BRAND</BrandTitle>
                <BrandList>
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/PNG.svg" />
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/Downy.svg" />
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/SOFSYS.svg" />
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/OralB.svg" />
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/febreze.svg" />
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/gillette.svg" />
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/pampers.svg" />
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/sweeper.svg" />
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/Aldicom.svg" />
                </BrandList>
            </BrandContainer>
            <BrandContainer>
                <BrandTitle>PLATFORM</BrandTitle>
                <BrandList>
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/MUSINSA.svg" />
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/coupang.svg" />
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/amazon.svg" />
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/NAVER.svg" />
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/todayHome.svg" />
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/cafe24.svg" />
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/gmarket.svg" />
                    <ListImage src="https://heidimoon.cafe24.com/promotion/0820/eleven.svg" />
                </BrandList>
            </BrandContainer>
        </>
    );
};

export default Mainpage;
const MainContainer = styled.div`
    width: 100%;
    position: relative;
    padding-top: 56.25%; /* 16:9 비율로 높이를 설정 */
    overflow: hidden;

    @media screen and (max-width: 768px) {
        padding-top: 75%; /* 모바일에서는 4:3 비율로 조정 */
    }
`;
const VideoBg = styled.video.attrs({
    playsInline: true, // 인라인 재생을 위해 playsInline 속성을 추가
})`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    pointer-events: none; /* 클릭 방지 */
`;
const MainBg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* 어두운 오버레이 */
    z-index: 2; /* 배경 오버레이가 비디오 위에 오도록 */
`;

const MainContents = styled.div`
    position: absolute;
    top: 20%; /* 부모 요소 높이의 50% 위치 */
    left: 15%; /* 부모 요소 너비의 50% 위치 */

    display: flex;
    flex-direction: column;
    gap: 32px;
    z-index: 3; /* 텍스트가 비디오와 배경 오버레이 위에 표시되도록 */
`;

const MainText = styled.p`
    font-size: ${({ theme }) => theme.fontSize.extra2};
    font-family: ${({ theme }) => theme.fonts.bold};
    color: white;
    line-height: 1.2;
    z-index: 3;

    @media all and (max-width: 1024px) {
        font-size: ${({ theme }) => theme.fontSize.logo};
    }

    @media all and (max-width: 768px) {
        font-size: ${({ theme }) => theme.fontSize.large};
    }
`;

const MainSub = styled.p`
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-family: ${({ theme }) => theme.fonts.medium};
    color: white;
    line-height: 1.2;
    z-index: 3;

    @media all and (max-width: 1024px) {
        font-size: ${({ theme }) => theme.fontSize.small};
    }
`;

const Mobr = styled.br`
    @media all and (min-width: 576px) {
        display: none;
    }
`;

const Pcbr = styled.br`
    @media all and (max-width: 576px) {
        display: none;
    }
`;
const SloganContainer = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 4% 0;
`;
const SloganBgImage = styled.div`
    background-image: url(https://cdn.imweb.me/thumbnail/20240430/9cd762a10a315.jpg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
`;

const SloganBg = styled.div`
    background-color: rgba(255, 255, 255, 0.85);
    -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#d8ffffff,endColorstr=#d8ffffff);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#d8ffffff,endColorstr=#d8ffffff);
    zoom: 1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
`;

const SloganTitle = styled.h1`
    font-size: ${({ theme }) => theme.fontSize.extra};
    line-height: 1.2;
    position: relative;
    z-index: 2;
    background: ${({ theme }) => theme.colors.sloganGradient};
    font-family: ${({ theme }) => theme.fonts.bold} !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    background-clip: text;

    @media all and (max-width: 1024px) {
        line-height: 0.9;
        margin-bottom: 1rem;
    }
`;

const SloganSub = styled.p`
    font-size: ${({ theme }) => theme.fontSize.small};
    line-height: 1.2;
    position: relative;
    z-index: 2;
    color: rgba(55, 55, 55);
    text-align: center;
`;

const MnBr1025 = styled.br`
    @media all and (min-width: 1025px) {
        display: none;
    }
`;

const MXBR375 = styled.br`
    @media all and (max-width: 375px) {
        display: none;
    }
`;

const PurposeContainer = styled.div`
    width: 100%;
    padding: 8% 0;
    background-color: #010633;
    position: relative;
    height: auto;
    box-sizing: border-box;

    @media all and (max-width: 1024px) {
        padding: 8% 16px;
        text-align: center;
    }
`;

const PurposeBg = styled.img`
    position: absolute;
    top: 20%;
    right: 0;
    width: 50%;
    height: auto;
    z-index: 1;

    @media all and (max-width: 1024px) {
        display: none;
    }
`;

const PurposeTitle = styled.h1`
    font-size: ${({ theme }) => theme.fontSize.logo};
    color: white;

    @media all and (max-width: 1024px) {
    }
`;

const PurposeSub = styled.p`
    font-size: ${({ theme }) => theme.fontSize.small};
    color: white;
    margin-top: 1rem;
    line-height: 1.5;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 60%;
    height: 100%;
    margin: 2rem 0 2rem 0;

    @media all and (max-width: 1024px) {
        width: 100%;
    }
`;
const GridItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* 공간을 균등하게 */
    width: 100%;
    max-width: 180px;
    gap: 32px;
    margin: 16px 0;

    @media all and (max-width: 1024px) {
        margin: 16px auto;
    }
`;

const Image = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-bottom: 1rem; /* 제목과 이미지 사이의 간격 */
    @media all and (max-width: 1024px) {
        margin: 0 auto;
    }
`;

const Title = styled.h3`
    margin: 0;
    font-size: 1.2rem;
    color: white;
    text-align: center; /* 텍스트 정렬 */
    line-height: 1.2;
    @media all and (max-width: 576px) {
        font-size: 1rem;
    }
`;

const BtnContainer = styled.div`
    margin-top: 4rem;
    display: flex;
    gap: 16px;

    @media all and (max-width: 1024px) {
        justify-content: center;
    }
`;

const MainDetailBtn = styled.div`
    border: 1px solid white;
    background-color: #2f3656;
    border-radius: 5px;
    padding: 16px 64px;
    min-height: auto;
    max-width: none;
    line-height: inherit;
    color: white;
    cursor: pointer;

    @media all and (max-width: 1024px) {
        padding: 8px 24px;
    }
`;

const MainInquiryBtn = styled.div`
    background-color: #4684dd;
    border-radius: 5px;
    padding: 16px 64px;
    min-height: auto;
    max-width: none;
    line-height: inherit;
    color: white;
    cursor: pointer;

    @media all and (max-width: 1024px) {
        padding: 8px 24px;
    }
`;

const PurposeLink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

const CareerContainer = styled.div`
    width: 100%;
    margin: 3.5rem auto;
    text-align: center;
`;

const CareerSub = styled.p`
    font-size: 1.5rem;
    color: hsl(248.04878048780486, 76.6355140186916%, 41.102478431372549%);
    margin-bottom: 1rem;
`;

const CareerTitle = styled.h1`
    color: rgb(17, 17, 17);
    font-size: 4rem;
    font-family: ${({ theme }) => theme.fonts.bold} !important;

    @media all and (max-width: 768px) {
        font-size: 3rem;
    }
`;

const CareerContent = styled.p`
    font-size: 1rem;
    line-height: 1.3;
    margin-top: 1rem;
`;

const BrandContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    text-align: center;
    max-width: 1280px;
    padding: 0 64px;

    @media all and (max-width: 1024px) {
        padding: 0;
    }
`;

const BrandTitle = styled.h1`
    color: rgb(17, 17, 17);
    font-size: 4rem;
    font-family: ${({ theme }) => theme.fonts.bold} !important;

    @media all and (max-width: 768px) {
        font-size: 3rem;
    }
`;

const BrandList = styled.div`
    margin: 2rem auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 1024px) {
        width: 90%;
    }
`;

const ListImage = styled.img`
    flex-basis: calc(25% - 32px);
    flex-grow: 1;
    max-width: 24%;
    margin-bottom: 16px;
    height: auto;

    @media (max-width: 1024px) {
        flex-basis: calc(50% - 16px); /* 한 줄에 2개씩 배치되도록 설정 */
        max-width: 48%;
    }
`;
