import styled from "styled-components";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";

const details_1 = {
    main: [
        {
            // mainImage: "https://heidimoon.cafe24.com/renwal/test2/detail_main.jpg",
            mainTitle: "Data Transformation",
            mainText: "데이터가 곧 자산인 시대. 이를 위한 변화",
        },
    ],

    functions: [
        {
            funcImg:
                "https://heidimoon.cafe24.com/renwal/test2/level1_1_1002.jpg",
            title: "Data Base Building",
            sub: "현재 비지니스가 가지고 있는 데이터를 데이터 베이스로 정리",
            reason: "비지니스의 decision making process 에 적용 하는 사례가 늘어나면서 data는 회사의 자산으로 여겨지고 있습니다. 더 많은 데이터를 정형화 하여 가지고 있을 수록, 빠른 extraction 과 visualization, decision making, 추후 정확한 인공지능 모델 적용이 가능합니다. ",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level1_2.jpg",
            title: "Cloud Migration",
            sub: "현재 로컬 컴퓨터에 가지고 있는 데이터  베이스 / 데이터를 클라우드로 옮기는 과정.",
            reason: "서버 관리나 보안 팀이 없는 회사일 수록 데이터 관리 / 해킹에 취약합니다. 글로벌 기업들과 같은 레벨의 보안과 데이터 접근성을 갖으려면 cloud migration은 선택이 아닌 필수 입니다.",
        },
        {
            funcImg:
                "https://heidimoon.cafe24.com/renwal/test2/level1_3_1002.jpg",
            title: "Live visualization",
            sub: "비지니스가 가지고 있는 KPI 등을 라이브로 보여주는 BI solution 구축",
            reason: "데이터를 갖고 있는 것 만으로는 비지니스는 변화하지 않습니다. 빠른 인사이트 추출과 decision making을 위하여, 현 비지니스 / 시장의 상황을 알 수 있는 Visualization이 필수 입니다. ",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level1_4.jpg",
            title: "Data Pipelining / 업무 자동화",
            sub: "현재 매뉴얼로 돌아가고 있는 업무 / 데이터 collection process 등 자동화 ",
            reason: "많은 업무들은 자동화의 기회 요인이 있고, 많은 경우 직원들의 업무 시간의 대부분을 차지 합니다. 이를 프로그램으로 대체함으로써, 직원들이 생산성을 대폭 향상 시킬 수 있습니다.",
        },
    ],
};

const details_2 = {
    functions: [
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level2_1.jpg",
            title: "시장 데이터 분석",
            sub: "당사 SKU들의 등수, 경쟁 SKU들의 가격대, 경쟁사들의 사용중인 hashtag, 마케팅 메시지 확인",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level2_2.jpg",
            title: "광고",
            sub: "당사 광고의 키워드별, SKU별 퍼포먼스 확인 및 트랜드 분석",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level2_3.jpg",
            title: "리뷰",
            sub: "네이버, 쿠팡, 카페24, 오늘의 집 등의 채널에서 본사와 경쟁사의 리뷰 collection ",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level2_4.jpg",
            title: "인터넷 여론 분석",
            sub: "메타 / 유튜브 / 네이버 카페, 블로그에서 관심 키워드 별 연관되어 있는 자연어 분석",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level2_5.jpg",
            title: "연관성 분석",
            sub: "현재 당사의 매출 / 검색 수 등과 가장 연관성이 높은 마케팅 / 매출 요소 확인",
        },
    ],
};

const details_3 = {
    main: [
        {
            // mainImage: "https://heidimoon.cafe24.com/renwal/test2/detail_main.jpg",
            mainTitle: "Machine Learning model building ",
            mainText:
                "어렵게만 느껴졌던 머신러닝. 내 비지니스를 위한 커스터마이즈 된 솔루션.",
        },
    ],
    functions: [
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level3_1.jpg",
            title: "비용 최적화 계산 모델",
            sub: "Ex ) 우리 제품의 네이버 광고 최적 비용은 어느정도 일까? 이 정도 투자를 하면 매출은 어느정도 올라가지?",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level3_2.jpg",
            title: "Supply Chain을 위한 수요 예측 모델",
            sub: "Ex) 다음달이 성수기인데 어느정도 재고를 준비해야 할까? 새로 주문하는 MOQ는 우리 비지니스에 reasonable 한가?",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level3_3.jpg",
            title: "당사 비지니스에 특화된 강화 학습 모델",
            sub: "Ex) 시장 상황과 우리 회사의 매출을 가지고 다음달 비지니스 액션을 알려주는 모델이 있으면 좋겠어",
        },
        {
            funcImg:
                "https://heidimoon.cafe24.com/renwal/test2/chatbot_img.jpeg",
            title: "당사 데이터를 학습 시킨 챗봇",
            sub: "Ex) 우리 제품에 대한 질문을 자동으로 답해주는 챗봇이 있으면 좋겠어",
        },
    ],
};
const getDetailsById = (id) => {
    switch (id) {
        case "1":
            return details_1;
        case "2":
            return details_2;
        case "3":
            return details_3;
        default:
            return [];
    }
};
const Detail = () => {
    const { id } = useParams(); // URL 파라미터에서 id를 가져옴
    const details = getDetailsById(id); // id에 따라 데이터를 선택

    // id가 "2"인 경우에만 다른 UI를 렌더링
    if (id === "2") {
        return <DetailCase2 />;
    }

    // 그 외의 id는 기존 UI를 렌더링
    return (
        <>
            <DetailWrapper>
                <DetailMainContainer>
                    <Wrapper>
                        <MainWrapper>
                            <DetailTitle>
                                {details.main[0].mainTitle}
                            </DetailTitle>
                            <MainImageWrapper>
                                <MainImage src={details.main[0]?.mainImage} />
                            </MainImageWrapper>
                            <DetailSub>{details.main[0]?.mainText}</DetailSub>
                        </MainWrapper>
                    </Wrapper>
                </DetailMainContainer>

                {details.functions.map((detail, index) => (
                    <DetailContainer key={index}>
                        <Wrapper reverse={index % 2 === 0}>
                            <ImageWrapper reverse={index % 2 === 1}>
                                <DetailImg src={detail.funcImg} />
                            </ImageWrapper>
                            <TextWrapper>
                                <DetailText>
                                    {index + 1}. {detail.title}
                                </DetailText>
                                <DetailSub>{detail.sub}</DetailSub>
                                {detail.reason && (
                                    <>
                                        <DetailText>Why?</DetailText>
                                        <DetailSub>{detail.reason}</DetailSub>
                                    </>
                                )}
                            </TextWrapper>
                        </Wrapper>
                    </DetailContainer>
                ))}
            </DetailWrapper>
        </>
    );
};

export default Detail;

const DetailWrapper = styled.div`
    padding: 120px 0;
    background-color: #010633;
    height: auto;
    width: 100%;
`;

const DetailMainContainer = styled.div`
    background-color: #010633;
    height: auto;
    width: 100%;
    padding: 0 16px;
`;

const DetailContainer = styled.div`
    margin-top: 64px;
    background-color: #010633;
    height: auto;
    width: 100%;
    padding: 0 16px;
`;

const Wrapper = styled.div`
    width: 100%;
    max-width: 860px;
    margin: 0 auto;
    display: flex;
    flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    @media (max-width: 768px) {
        flex-direction: column; /* 768px 이하에서는 이미지와 텍스트를 세로로 쌓기 */
        justify-content: center;
        align-items: center;
    }
`;

const MainWrapper = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%; /* 텍스트도 전체 너비 사용 */
    }
`;

const MainImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 100%; /* 작은 화면에서는 전체 너비를 사용 */
        justify-content: center; /* 중앙 정렬 */
    }
`;

const MainImage = styled.img`
    width: 100%;
    max-width: 400px;
    height: auto;
    box-sizing: border-box;
    object-fit: none;
    border-radius: 30px;
`;

const ImageWrapper = styled.div`
    width: 50%;
    display: flex;
    justify-content: ${({ reverse }) => (reverse ? "flex-start" : "flex-end")};
    align-items: center;

    @media (max-width: 768px) {
        width: 100%; /* 작은 화면에서는 전체 너비를 사용 */
        justify-content: center; /* 중앙 정렬 */
        max-width: 400px;
    }
`;

const DetailImg = styled.img`
    width: 100%;

    height: auto;
    box-sizing: border-box;
    object-fit: contain;
    border-radius: 30px;
`;

const TextWrapper = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%;
        align-items: center;
        max-width: 400px;
    }
`;

const DetailTitle = styled.p`
    font-size: ${({ theme }) => theme.fontSize.logo};
    font-family: ${({ theme }) => theme.fonts.bold};
    line-height: 1.3;
    color: white;

    @media all and (max-width: 768px) {
        padding: 0 32px;
        font-size: ${({ theme }) => theme.fontSize.medium} !important;
    }
`;

const DetailText = styled.p`
    font-size: ${({ theme }) => theme.fontSize.large};
    font-family: ${({ theme }) => theme.fonts.bold};
    line-height: 1.3;
    color: white;

    @media all and (max-width: 768px) {
        padding: 0 32px;
        font-size: ${({ theme }) => theme.fontSize.medium} !important;
    }
`;

const DetailSub = styled.p`
    font-size: ${({ theme }) => theme.fontSize.small} !important;
    color: white;
    line-height: 1.5;
    font-family: ${({ theme }) => theme.fonts.regular} !important;
    margin-top: 1rem;
    margin-bottom: 64px;

    @media all and (min-width: 768px) {
        width: 100%;
    }

    @media all and (max-width: 768px) {
        padding: 0 32px;
    }
`;

const DetailCase2 = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 465);

    // 윈도우 리사이즈 이벤트 처리
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 465);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // 부모 요소의 30%를 차지하도록 설정 (3개의 슬라이드 보이기)
        slidesToScroll: 1,

        autoplay: true, // 자동 슬라이드
        autoplaySpeed: 3000, // 3초마다 자동 슬라이드
        centerMode: true,
        centerPadding: "0px", // 슬라이드 간 간격을 설정하기 위해 필요

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <SpecialWrapper>
            <SpecialText>Data Analytics</SpecialText>
            <SubText>
                트랜드 변화를 파악을 남들보다 빠르게. Business question의 답은
                데이터에 있습니다.
            </SubText>
            <SpecialContainer>
                <SpecialFunctions>
                    {isMobile ? (
                        <SpecialTitle>
                            온라인에 존재하는 <br /> 데이터를 원하시는
                            <br /> scope 에 따라
                            <br /> 모아드립니다.
                        </SpecialTitle>
                    ) : (
                        <SpecialTitle>
                            온라인에 존재하는 데이터를 원하시는
                            <br /> scope 에 따라 모아드립니다.
                        </SpecialTitle>
                    )}

                    <SpecialSub>
                        경쟁사 / 본사의 현 상황을 인터넷에 존재하는 데이터를
                        통해 확인 하기 위한 필수 과정입니다.
                    </SpecialSub>
                    <SpecialSub>
                        *본사의 데이터 베이스가 있어야 합니다.
                    </SpecialSub>
                </SpecialFunctions>
            </SpecialContainer>

            <SliderWrapper>
                <Slider {...settings}>
                    {details_2.functions.map((item, index) => (
                        <AnalysisWrapper>
                            <Analysis key={index}>
                                <AnalysisImage
                                    src={item.funcImg}
                                    alt={item.title}
                                />
                                <AnalysisTitle>{item.title}</AnalysisTitle>
                                <AnalysisSub>{item.sub}</AnalysisSub>
                            </Analysis>
                        </AnalysisWrapper>
                    ))}
                </Slider>
            </SliderWrapper>

            <SpecialContainer>
                <SpecialFunctions>
                    <SpecialTitle>
                        비지니스의 문제점을 데이터 측면에서 분석 후,
                        <Pcbr /> business action 추천
                    </SpecialTitle>
                    <SpecialSub>
                        많은 분석 회사들이 비지니스의 이해 없이 단순 통계적
                        분석만을 해주는 것과 달리, 데이터코어는
                        <Pcbr /> 비지니스 perspective에서 insight extraction 후,
                        어떠한 액션이 필요한지 알려 드립니다.
                    </SpecialSub>
                </SpecialFunctions>
            </SpecialContainer>
        </SpecialWrapper>
    );
};

const SpecialWrapper = styled.div`
    padding: 120px 32px;
    background-color: #010633;
    height: auto;
    width: 100%;
    box-sizing: border-box;

    /* @media all and (max-width: 768px) {
        padding: 120px 16px;
    } */
`;

const SpecialText = styled.p`
    font-size: ${({ theme }) => theme.fontSize.logo} !important;
    font-family: ${({ theme }) => theme.fonts.bold} !important;
    line-height: 1.3;
    color: white;
    text-align: center;
    @media all and (max-width: 768px) {
        padding: 0 32px;
        font-size: ${({ theme }) => theme.fontSize.medium} !important;
    }
`;

const SpecialContainer = styled.div`
    background-color: #f0f0f0;
    width: 100%;
    margin: 0 auto;
    padding: 48px 16px;
    border-radius: 24px;
    margin-top: 64px;
    max-width: 1280px;
    box-sizing: border-box;
`;

const SliderWrapper = styled.div`
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
`;

const AnalysisWrapper = styled.div`
    width: 100% !important;
    height: auto;
    padding: 8px;
`;

const Analysis = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 64px;
    height: auto;
`;

const AnalysisTitle = styled.p`
    font-size: ${({ theme }) => theme.fontSize.large};
    font-family: ${({ theme }) => theme.fonts.bold};
    color: white;
    margin: 16px 0;
`;

const AnalysisSub = styled.p`
    font-size: ${({ theme }) => theme.fontSize.small};
    font-family: ${({ theme }) => theme.fonts.regular};
    color: white;
`;

const AnalysisImage = styled.img`
    width: 100%;
    height: auto;

    box-sizing: border-box;
    object-fit: cover;
    border-radius: 24px;
`;
const SpecialTitle = styled.h1`
    font-size: ${({ theme }) => theme.fontSize.large};
    font-family: ${({ theme }) => theme.fonts.medium};
    color: #000000;
    text-align: center;

    @media all and (max-width: 768px) {
        font-size: ${({ theme }) => theme.fontSize.medium};
    }
`;

const SubText = styled.p`
    font-size: ${({ theme }) => theme.fontSize.small};
    font-family: ${({ theme }) => theme.fonts.regular};
    color: white;
    text-align: center;
    line-height: 1.5;
`;

const SpecialSub = styled.p`
    font-size: ${({ theme }) => theme.fontSize.small};
    font-family: ${({ theme }) => theme.fonts.regular};
    color: #000000;
    text-align: center;
    line-height: 1.5;
`;

const SpecialFunctions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Pcbr = styled.br`
    @media all and (max-width: 1280px) {
        display: none;
    }
`;
